@font-face {
font-family: '__recklessNeue_05c170';
src: url(/_next/static/media/4199399bb8246861-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__recklessNeue_Fallback_05c170';src: local("Arial");ascent-override: 88.98%;descent-override: 20.75%;line-gap-override: 0.00%;size-adjust: 102.16%
}.__className_05c170 {font-family: '__recklessNeue_05c170', '__recklessNeue_Fallback_05c170', sans-serif
}.__variable_05c170 {--f-serif: '__recklessNeue_05c170', '__recklessNeue_Fallback_05c170', sans-serif
}

@font-face {
font-family: '__activeGrotesk_4a9d2f';
src: url(/_next/static/media/0df46036a6d08592-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__activeGrotesk_4a9d2f';
src: url(/_next/static/media/c0847dfc75394886-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__activeGrotesk_4a9d2f';
src: url(/_next/static/media/fad79c547a2a4c74-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__activeGrotesk_4a9d2f';
src: url(/_next/static/media/ea36785919cba894-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__activeGrotesk_Fallback_4a9d2f';src: local("Arial");ascent-override: 98.59%;descent-override: 23.74%;line-gap-override: 0.00%;size-adjust: 102.34%
}.__className_4a9d2f {font-family: '__activeGrotesk_4a9d2f', '__activeGrotesk_Fallback_4a9d2f', sans-serif
}.__variable_4a9d2f {--f-sans: '__activeGrotesk_4a9d2f', '__activeGrotesk_Fallback_4a9d2f', sans-serif
}

@font-face {
font-family: '__activeGroteskHeadings_5d31f3';
src: url(/_next/static/media/0df46036a6d08592-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__activeGroteskHeadings_5d31f3';
src: url(/_next/static/media/c0847dfc75394886-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__activeGroteskHeadings_5d31f3';
src: url(/_next/static/media/fad79c547a2a4c74-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__activeGroteskHeadings_5d31f3';
src: url(/_next/static/media/ea36785919cba894-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__activeGroteskHeadings_Fallback_5d31f3';src: local("Arial");ascent-override: 98.59%;descent-override: 23.74%;line-gap-override: 0.00%;size-adjust: 102.34%
}.__className_5d31f3 {font-family: '__activeGroteskHeadings_5d31f3', '__activeGroteskHeadings_Fallback_5d31f3', sans-serif
}.__variable_5d31f3 {--f-serif: '__activeGroteskHeadings_5d31f3', '__activeGroteskHeadings_Fallback_5d31f3', sans-serif
}

